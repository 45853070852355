import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/new-age.scss';

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                author
                description
                image
                keywords
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              meta={[
                {
                  name: 'description',
                  content: 'Jobba hos Not Not Consulting AB?',
                },
                { name: 'keywords', content: 'site, web' },
                {
                  property: `og:title`,
                  content: 'Medarbetare till Not Not Consulting',
                },
                {
                  property: `og:description`,
                  content: 'Vi söker nu fler systemutvecklare, klicka och läs mer!',
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  property: `og:image`,
                  content: 'https://notnot-career.web.app/photo-1499951360447-b19be8fe80f5.png',
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: `@notnotse`,
                },
                {
                  name: `twitter:title`,
                  content: 'Medarbetare till Not Not Consulting',
                },
                {
                  name: `twitter:description`,
                  content: 'Vi söker nu fler systemutvecklare, intresserad klicka och läs mer!',
                },
                {
                  name: `twitter:image`,
                  content: 'https://notnot-career.web.app/photo-1499951360447-b19be8fe80f5.png',
                },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div className={'page-top'}>{children}</div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  noSiteHeader: PropTypes.bool,
  activeLink: PropTypes.string,
};

export default Layout;
