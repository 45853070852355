import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p></p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="/privacypolicy.html">Privacy</a>
          </li>
        </ul><p>
        Not Not Consulting AB<br></br>Nytorget 2, 852 32 Sundsvall
        </p>
      </div>
    </footer>
  );
}
